@import "@scss/common/variables";

.header__dropdown .dropdown-content {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #1d4d40;
  color: $white-color;
}
.switch_header__dropdown .dropdown-content {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.header-wrapper {
  padding: 6px 0;
  border-bottom: 1px solid $theme-border-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white-color;
  z-index: 8;
}

.switch-dropdown {
  margin-right: 20px;
}
.switch-dropdown .dropdown-header {
  display: flex;
  justify-content: space-between;
}
.switch-dropdown .dropdown .btn {
  height: 34px;
}
.switch-dropdown .btn-secondary {
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary.dropdown-toggle {
  border: none !important;
}
.switch-dropdown .btn {
  padding: 0 0px;
  border: none;
}
.switch-dropdown .dropdown {
  &:before {
    height: 0;
    width: 0;
    top: 0;
  }
}
.switch-dropdown .dropdown-menu {
  padding: 0;
  transform: translate3d(0, 47.5px, 0) !important;
  min-width: 200px;
}
.switch-dropdown .dropdown-item {
  padding: 0px 0 8px !important;
  margin: 0;
  text-align: center;

  p {
    text-align: center;
  }
  &:hover {
    color: #d7242e;
    background-color: transparent !important;
  }
}
.switch-dropdown .switch-dropdown-item-header {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  p {
    font-size: 16px;
  }
  img {
    height: 19px;
    width: 19px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
      border-radius: 50%;
      padding: 1px;
    }
  }
}
.switch-dropdown .switch-dropdown-item {
  display: flex;
  padding: 7px;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  width: 100%;
  p {
    font-size: 14px;
  }
  &:hover {
    // color: #d7242e;
    background-color: transparent !important;
  }

  &.active {
    color: #d7242e;
    background-color: #f7f7f7 !important;
    border-left: 2px solid #d7242e;
  }
}

.bulk-dropdown {
  margin-right: 10px;
}

.bulk-dropdown .dropdown .btn {
  height: 34px;
}
.bulk-dropdown .btn-secondary {
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary.dropdown-toggle {
  border: none !important;
}
.bulk-dropdown .btn {
  padding: 0 0px;
  border: none;
}

.bulk-dropdown .dropdown {
  &:before {
    height: 0;
    width: 0;
    top: 0;
  }
}

.bulk-dropdown .dropdown-menu {
  padding: 0;
  transform: translate3d(0, 47.5px, 0) !important;
  min-width: 240px;
}

.bulk-dropdown .dropdown-item {
  padding: 0px 0 8px !important;
  margin: 0;
  text-align: center;

  p {
    text-align: center;
  }

  &:hover {
    color: #1a73e8;
    background-color: transparent !important;
  }

  &.active {
    color: #1a73e8;
    background-color: transparent !important;
  }
}

.dropdown-content {
  margin: 0 auto;
}

.header-btn {
  height: 34px;
  line-height: 34px;
  border: 1px solid $theme-border-color;
  padding: 0 24px;
  border-radius: 20px;
  margin-top: 8px;
  background-color: $white-color;
  font-weight: 400;
}
