.container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
  }
  .centered {
    height: 65vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }