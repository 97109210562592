@import "@scss/common/variables";
.organization_container{
  height: calc(100vh - 120px);
	max-height: calc(100vh - 120px);
	overflow: auto;
}

.checkpoint-active-details-wrapper__form-content {
  max-width: 744px;
  margin: 0 auto;
  width: 100%;
}
.checkpoint-active-details-wrapper__form-content_email_template {
  max-width: 650px;
  margin: 30px;
  width: 100%;
}
.checkpoint-active-wrapper {
  position: relative;
  top: 65px;
  width: 100%;
}

.nav-link-image {
  margin: 24px 24px;
}

.user-details-wrapper__header--new-header {
  position: sticky;
  top: 65px;
  background-color: #fff;
  z-index: 1;
}

.checkpoint-active-details-wrapper__form-wrapper {
  padding-top: 24px;
}

.css-1s2u09g-control {
  border-color: $theme-border-color  !important;
  min-height: 40px;
  min-width: 364px;
}

.css-14el2xx-placeholder {
  color: #dadce0 !important;
}

.user-details-wrapper__form-group label.checkpoint-active-details__label {
  font-weight: 500;
  color: #5f6368 !important;
  font-size: 12px;
  gap:24px;
}


.org-profile-wrapper{
  max-width: 744px;
  width: 100%;
  margin: 24px auto 0;
}
.org-profile-wrapper-image{
  flex:1;
  transition: 0.3ms all ease-in-out;
  border: 1px dashed rgba(0,0,0,0.08);
  border-radius: 4px;
  transition: all 0,5s ease-in-out;
  &:hover{
    box-shadow: 4px 4px 10px #00000029;
    border-color: rgba(var(--secondary-color), 1);;
  }
}
.org-profile-wrapper-image img {
  object-fit: contain;
}
.org-profile-wrapper__details{
  margin-left: 24px;
}
.org-profile-wrapper__details-heading{
  color:#3C4043;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.org-profile-wrapper__details-text{
  color:#5F6368;
  font-size: 14px;
  font-weight: 400;
  span{
    display: block;
    margin-top: 8px;
    color:rgba(var(--primary-color),1);
  }
}

.custom-select .css-1s2u09g-control{
  min-width: 744px!important;
}

//for grid
.user-details-wrapper__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  &--grid-gap-vendor-profile {
    gap: 0 24px;
  }
  &--org-profile {
    gap: 0 24px;
  }
}