@import '@scss/common/variables';
.main-wrapper {
	margin-top: 65px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	height: calc(100vh - 65px);

	.main-user-wrapper {
		max-height: calc(100vh - 65px);
		height: calc(96vh - 65px);
		overflow-y: auto;
		border-right: 1px solid $theme-border-color;
	}
	.dashboard-wrapper {
		flex-direction: column;
		max-width: calc(100% - 170px);
		// max-width: calc(100% - 185px);
		width: 100%;
		overflow-x: auto;
	
		.dashboard-wrapper__header {
			padding: 12px 24px;
			box-shadow: 0px 3px 6px #00000029;
			background-color: $white-color;
			z-index: 3;
			position: sticky;
			top: 0;

			.dashboard-wrapper__header-title {
				color: #3c4043;
				font-size: 20px;
				font-weight: 400;
			}
		}
		.footer-container{
			position: absolute;
			background-color: rgba(var(--primary-color), 0.1);
			// background-color: #fdfeff;
			// box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;			
			// box-shadow: 0px 0px 6px 6px #00000029;
			color: rgba(var(--primary-color), 1);
			padding: 11px;
			bottom: 0;
			left: 0;
			width:169px;
			border-right: 1px solid $theme-border-color;

			z-index: 1;
			p{
				font-size: 13px;
				text-align: center;
			}
		}
	}
}


.main-wrapper--no-navigation-menu {
	margin-top: 65px;
}
.main-wrapper__content {
	height: calc(100vh - 65px);
	max-height: calc(100vh - 65px);
	overflow-y: auto;
	position: relative;
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: contain;
}
.footer-wrapper--task {
	position: absolute;
	left: 0;
	right: 0;
}
.dashboard-wrapper__dropdown {
	color: $theme-font-heading-color;
	font-weight: $medium-font-weight;
	margin: 0 10px;
	font-size: 18px;
}
.dashboard-wrapper__apply-btn {
	border: 1px solid $theme-border-color;
	background-color: $white-color;
	padding: 0px 16px;
	height: 34px;
	line-height: 34px;
	margin-left: 12px;
	border-radius: $base-border-radius;
	color: #3c4043;
	transition: 0.3s all ease-in-out;
	&:hover {
		color: $theme-secondary-color;
		border-color: $theme-secondary-color;
	}
}
.dropdown-toggle::after {
	content: none;
}
