@import "@scss/common/variables";
.common-search-wrapper__filter-container {
  max-width: 70px;
  width: 100%;
}
.common-search-wrapper__filter {
  .common-search-wrapper__filter-form-group input.common-search-wrapper__filter-search-input {
    border: 1px solid $theme-border-color;
    padding: 0 16px 0 28px;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    min-width: 250px;
    width: 100%;
    font-size: 14px;
  }
  .common-search-wrapper__filter-form-group {
    position: relative;
    .common-search-wrapper__filter-input-image {
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      height: 18px;
      width: 18px;
    }
    .common-search-wrapper__filter-image-close {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
  .common-search-wrapper__filter-image {
    height: 24px;
    width: 24px;
    margin: 0 auto;
    cursor: pointer;
  }
  .common-search-wrapper__filter-title {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    min-width: 77px;
    width: 100%;
    cursor: pointer;
  }
}
